import ThumbImage1 from "../../images/product-1.png";
import ThumbImage2 from "../../images/product-2.png";
import ThumbImage3 from "../../images/product-3.png";
import ThumbImage4 from "../../images/product-4.png";
import ThumbImage5 from "../../images/product-5.png";

export const photos = [
  {
    src: ThumbImage1,
  },
  {
    src: ThumbImage2,
  },
  {
    src: ThumbImage3,
  },
  {
    src: ThumbImage4,
  },
  {
    src: ThumbImage5,
  },
];
